import React, { useEffect, useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import useCurrentWidth from "./useCurrentWidth";
import { mens, womens, accessories, youth, matchingSets } from "./routes";
import DetectSwipe from "./DetectSwipe";

export default function MainPage() {
  const width = useCurrentWidth();
  const [catNumber, setCatNumber] = useState(width > 1024 ? 6 : width > 768 ? 4 : 3);
  const [catNumber2, setCatNumber2] = useState(width > 1024 ? 3 : width > 768 ? 2 : 1);
  const [start, setStart] = useState({0: 0, 1: 0, 2: 0, 3: 0, 4: 0});
  const [end, setEnd] = useState({0: catNumber, 1: catNumber, 2: catNumber, 3: catNumber, 4: catNumber2});
  const imgs = [{image: "/Images/mainPage/category/BlueHeart.png", link: "/search?search=Blue"},{image: "/Images/mainPage/category/GreenHeart.png", link: "/search?search=Green"},
  {image: "/Images/mainPage/category/PinkHeart.png", link: "/search?search=Pink"}, {image: "/Images/mainPage/category/PurpleHeart.png", link: "/search?search=Purple"},
  {image: "/Images/mainPage/category/WhiteHeart.png", link: "/search?search=Neutral"},{image: "/Images/mainPage/category/RedHeart.png", link: "/search?search=Red"},
  {image: "/Images/mainPage/category/YellowHeart.png", link: "/search?search=Yellow"},{image: "/Images/mainPage/category/OrangeHeart.png", link: "/search?search=Orange"},
  {image: "/Images/mainPage/category/TurquoiseHeart.png", link: "/search?search=Turquoise"}]
  useEffect(() => {
    setCatNumber(width > 1024 ? 6 : width > 768 ? 4 : 3);
    setCatNumber2(width > 1024 ? 3 : width > 768 ? 2 : 1);
    setStart({0: 0, 1: 0, 2: 0, 3: 0, 4: 0});
    setEnd({0: catNumber, 1: catNumber, 2: catNumber, 3: catNumber, 4: catNumber2});
  }, [width, catNumber, catNumber2]);
  function showCat(name, cat, index)
  {
    return <>
    <DetectSwipe
          onSwipeRight={() => {if((start[index] > 0)) {setEnd({...end, [index]: (end[index] - catNumber)}); setStart({...start, [index]: (start[index]-catNumber)})}}}
          onSwipeLeft={() => {if((cat.length > end[index])){setEnd({...end, [index]: end[index] + catNumber}); setStart({...start, [index]: start[index]+catNumber})}}}
    >
    <div style={{borderBottom: `2px solid #b8020e`, marginBottom: `10px`}}/>
    <div style={{paddingLeft: `10vw`}}>
      <h2 style={{color: `#e37100`, fontWeight: `bold`, fontSize: width < 700 ? `16px` : `2.2vw`}}>{name}</h2>
      <div  style={{display: `flex`, alignContent: `center`, alignItems: `center`}}>
      {(start[index] > 0) ? <div role="button" tabIndex="0" style={{cursor: `pointer`, paddingBottom: `17px`}} 
      onKeyUp={(e) => {if (e.key === 'Enter'){
        setEnd({...end, [index]: (end[index] - catNumber)}); setStart({...start, [index]: (start[index]-catNumber)})
      }}}
      onClick={() => {setEnd({...end, [index]: (end[index] - catNumber)}); setStart({...start, [index]: (start[index]-catNumber)})}}><img alt="left arrow" src="/Images/arrowL.png" style={{height: `16px`}}/></div> : <div style={{padding: `11px`}} />}
    {cat.map((cat, i) => (
        (i >= start[index] && i < end[index]) &&<div key={i} style={{width: width > 1024 ? `13vw` : width > 768 ? `20vw` : `25vw`}}>
         <a href={cat.link}>
        <div style={{display: `flex`, justifyContent: `center`}}><img alt="product" style={{width: width > 1024 ? `13vw` : width > 768 ? `20vw` : `25vw`, height: width > 1024 ? `13vw` : width > 768 ? `20vw` : `25vw` }} src={cat.image} /></div>
        <div style={{display: `flex`, justifyContent: `center`}}><p style={{color: `#e37100`, textAlign: `center`}}>{cat.displayText}</p></div>
        </a>
      </div>
    ))}{(cat.length > end[index]) && 
    <div style={{cursor: `pointer`, paddingBottom: `17px`}} role="button" tabIndex="0" 
    onKeyUp={(e) => {if (e.key === 'Enter'){
      setEnd({...end, [index]: end[index] + catNumber}); setStart({...start, [index]: start[index]+catNumber})
    }}}
        onClick={() => {setEnd({...end, [index]: end[index] + catNumber}); setStart({...start, [index]: start[index]+catNumber})}}><img alt="right arrow" src="/Images/arrowR.png" style={{height: `16px`}}/></div>}</div>
    </div>
    </DetectSwipe>
    </>
  }
  function showHeart()
  {
    return <>
    <div style={{borderBottom: `2px solid #b8020e`, marginBottom: `20px`}}/>
    <div style={{paddingLeft: `10vw`}}>
      <h2 style={{color: `#e37100`, fontWeight: `bold`, fontSize: width < 700 ? `16px` : `2.2vw`}}>Search by Color</h2>
      {width > 550 ? <div  style={{display: `flex` }}>
    {imgs.map((heart, i) => (
        <div key={i} style={{width: `8.5vw`}}>
         <a href={heart.link}>
        <div style={{display: `flex`, justifyContent: `center`}}><img alt="heart" style={{width: `8.5vw`, height: `6.375vw` }} src={heart.image} /></div>
        </a>
      </div>
    ))}</div> : <><div  style={{display: `flex`}}>
    {imgs.slice(0,3).map((heart, i) => (
        <div key={i} style={{width: `25.5vw`}}>
         <a href={heart.link}>
        <div style={{display: `flex`, justifyContent: `center`}}><img alt="heart" style={{width: `25.5vw`, height: `19.125vw` }} src={heart.image} /></div>
        </a>
      </div>
    ))}</div><div  style={{display: `flex`}}>
    {imgs.slice(3,6).map((heart, i) => (
        <div key={i} style={{width: `25.5vw`}}>
         <a href={heart.link}>
        <div style={{display: `flex`, justifyContent: `center`}}><img alt="heart" style={{width: `25.5vw`, height: `19.125vw` }} src={heart.image} /></div>
        </a>
      </div>
    ))}</div><div  style={{display: `flex`}}>
    {imgs.slice(6).map((heart, i) => (
        <div key={i} style={{width: `25.5vw`}}>
         <a href={heart.link}>
        <div style={{display: `flex`, justifyContent: `center`}}><img alt="heart" style={{width: `25.5vw`, height: `19.125vw` }} src={heart.image} /></div>
        </a>
      </div>
    ))}</div></>}
    </div></>
  }
  function showCat2(name, cat, index)
  {
    return <>
    <DetectSwipe 
      onSwipeRight={() => {if((start[index] > 0)) {setEnd({...end, [index]: (end[index] - catNumber2)}); setStart({...start, [index]: (start[index]-catNumber2)})}}}
      onSwipeLeft={() => {if((cat.length > end[index])){setEnd({...end, [index]: end[index] + catNumber2}); setStart({...start, [index]: start[index]+catNumber2})}}}
    >
    <div style={{borderBottom: `2px solid #b8020e`, marginBottom: `10px`}}/>
    <div style={{paddingLeft: `10vw`}}>
      <h2 style={{color: `#e37100`, fontWeight: `bold`, fontSize: width < 700 ? `16px` : `2.2vw`}}>{name}</h2>
      <div  style={{display: `flex`, alignContent: `center`, alignItems: `center`}}>
          {(start[index] > 0) ? 
          <div role="button" tabIndex="0" style={{cursor: `pointer`, paddingBottom: `17px`}} 
          onKeyUp={(e) => {if (e.key === 'Enter'){
            setEnd({...end, [index]: (end[index] - catNumber2)}); setStart({...start, [index]: (start[index]-catNumber2)})
          }}}
          onClick={() => {setEnd({...end, [index]: (end[index] - catNumber2)}); setStart({...start, [index]: (start[index]-catNumber2)})}}><img alt="left arrow" src="/Images/arrowL.png" style={{height: `16px`}}/></div> : <div style={{padding: `11px`}} />}
        {cat.map((cat, i) => (
            (i >= start[index] && i < end[index]) &&<div key={i} style={{width: width > 1024 ? `26vw` : width > 768 ? `40vw` : `75vw`}}>
            <a href={cat.link}>
            <div style={{display: `flex`, justifyContent: `center`}}><img alt="product" style={{width: width > 1024 ? `26vw` : width > 768 ? `40vw` : `75vw`, height: width > 1024 ? `13vw` : width > 768 ? `20vw` : `37.5vw` }} src={cat.image} /></div>
            <div style={{display: `flex`, justifyContent: `center`}}><p style={{color: "#e37100", textAlign: `center`}}>{cat.displayText}</p></div>
            </a>
          </div>
        ))}{(cat.length > end[index]) && 
        <div role="button" tabIndex="0" style={{cursor: `pointer`, paddingBottom: `17px`}} 
              onKeyUp={(e) => {if (e.key === 'Enter'){
                setEnd({...end, [index]: end[index] + catNumber2}); setStart({...start, [index]: start[index]+catNumber2})
              }}}
              onClick={() => {setEnd({...end, [index]: end[index] + catNumber2}); setStart({...start, [index]: start[index]+catNumber2})}}>
          <img alt="right arrow" src="/Images/arrowR.png" style={{height: `16px`}}/>
        </div>}
      </div>
    </div>
    </DetectSwipe>
    </>
  }
  return(<>
  <div style={{background: `transparent`, minHeight: (width <= 500) ? `calc( 100vh - 175px )` : `100vh`}}>
    <div style={{color: `black`, paddingTop: `50px`}}>
      <div className="banner" style={{
              backgroundImage: "url(/Images/mainPageBanner.png)"
            }}>
        <span style={{marginTop: `25px`}} />
      </div>
      <div style={{marginTop: `5vh` }} />
      <Carousel pause={false} indicators={false} interval={3500} controls={false}>
      <Carousel.Item className="w-100">
        <div className="w-100" style={{display: `flex`, justifyContent: `center`, alignItems: `center`, height: `30vw`}}>
        <img
          src={window.location.origin + "/Images/mainPage/bipolar.png"}
          style={{width: `30vw`, marginLeft: `20vw`}}
          alt="First slide"
        />
        <div style={{width: `30vw`, marginRight: `20vw`, display: `flex`, justifyContent: `center`, alignItems: `center`, height: `30vw`, fontSize: `2vw`}}>
          Our apparel consists of high end material, vibrant coloring, and unique designs.
        </div>
        </div>
      </Carousel.Item>
      <Carousel.Item className="w-100">
        <div className="w-100" style={{display: `flex`, justifyContent: `center`, alignItems: `center`, height: `30vw`}}>
        <img
          src={window.location.origin + "/Images/mainPage/diabetes.png"}
          style={{width: `30vw`, marginLeft: `20vw`}}
          alt="First slide"
        />
        <div style={{width: `30vw`, marginRight: `20vw`, display: `flex`, justifyContent: `center`, alignItems: `center`, height: `30vw`, fontSize: `2vw`}}>
          We aim to provide awareness, support, and messages of hope.
        </div>
        </div>
      </Carousel.Item>
      <Carousel.Item className="w-100">
        <div className="w-100" style={{display: `flex`, justifyContent: `center`, alignItems: `center`, height: `30vw`}}>
        <img
          src={window.location.origin + "/Images/mainPage/positivity.png"}
          style={{width: `30vw`, marginLeft: `20vw`}}
          alt="First slide"
        />
        <div style={{width: `30vw`, marginRight: `20vw`, display: `flex`, justifyContent: `center`, alignItems: `center`, height: `30vw`, fontSize: `2vw`}}>
          We want you to feel great and look amazing in our fashionable apparel.
        </div>
        </div>
      </Carousel.Item>
      <Carousel.Item className="w-100">
        <div className="w-100" style={{display: `flex`, justifyContent: `center`, alignItems: `center`, height: `30vw`}}>
        <img
          src={window.location.origin + "/Images/mainPage/set.png"}
          style={{width: `40vw`, marginLeft: `20vw`}}
          alt="First slide"
        />
        <div style={{width: `20vw`, marginRight: `20vw`, display: `flex`, justifyContent: `center`, alignItems: `center`, height: `30vw`, fontSize: `2vw`}}>
          Each design consists of matching items.
        </div>
        </div>
      </Carousel.Item>
      <Carousel.Item className="w-100">
        <div className="w-100" style={{display: `flex`, justifyContent: `center`, alignItems: `center`, height: `30vw`}}>
        <img
          src={window.location.origin + "/Images/mainPage/matchingColor.png"}
          style={{width: `40vw`, marginLeft: `20vw`}}
          alt="First slide"
        />
        <div style={{width: `20vw`, marginRight: `20vw`, display: `flex`, justifyContent: `center`, alignItems: `center`, height: `30vw`, fontSize: `2vw`}}>
          Each design comes in many different color variations.
        </div>
        </div>
      </Carousel.Item>
    </Carousel>
    </div>
    {showCat("Men's Clothing", mens, "0")}
    {showCat("Women's Clothing", womens, "1")}
    {showCat("Kid's/Youth Clothing", youth, "2")}
    {showCat("Accessories", accessories, "3")}
    {showCat2("Matching Sets", matchingSets, "4")}
    {showHeart()}
    <div style={{borderBottom: `2px solid #b8020e`, marginBottom: `30px`, marginTop: `20px`}}/>
    <div style={{paddingLeft: `10vw`, paddingRight: `10vw`, marginBottom: `30px`}}>
      <p  style={{display: `flex`, color: `black`}}>
        Our goal is to bring awareness and support to as many people as possible. If you are looking for something specific that we don't currently have on our site,
        we would love to hear from you so we can get working on it!
      </p>
      <p  style={{display: `flex`, color: `black`}}>
        To do so, you can directly message us through any of the following links below, or email us at strongerthanmed@gmail.com
      </p>
    </div>
    <div style={{paddingLeft: `10vw`}}>
      <h3 style={{color: `#e37100`, fontWeight: `bold`, fontSize: `2.2vw`, marginBottom: `10px`}}>Follow Us!</h3>
    </div>
    <div style={{paddingLeft: `10vw`, paddingRight: `10vw`}}>
    <div style={{color: `#e37100`, fontWeight: `bold`, marginBottom: `5px`}}>
        <img alt="twitter" src="/Images/twitter.png" style={{height: `25px`, paddingRight: `5px`}} />
        Stronger_Than_Medicine
      </div>
      <div style={{color: `#e37100`, fontWeight: `bold`}}>
        <img alt="instagram" src="/Images/instagram.png" style={{height: `25px`, paddingRight: `5px`}} />
        Stronger_Than_Medicine
      </div>
    </div>
</div>
    </>);
}
