import * as React from "react"
import Layout from "../Components/layout";
import MainPage from "../Components/mainpage";
import { Seo } from "../Components/seo";


export default function Index() {
  return (
    <Layout>
      <MainPage />
    </Layout>
  )
}

export const Head = () => (
  <Seo />
)
