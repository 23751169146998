
import React, { useState } from "react";
export default function DetectSwipe({children, onSwipeLeft = () => {console.log("swiped left")}, onSwipeRight = () => {console.log("swiped right")}}) {
    const [touchStart, setTouchStart] = useState(null);
    const [touchStartY, setTouchStartY] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null);
    const [touchEndY, setTouchEndY] = useState(null);
    
    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50 
    
    const onTouchStart = (e) => {
      setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
      setTouchStart(e.targetTouches[0].clientX)
      setTouchStartY(e.targetTouches[0].clientY)
    }
    
    const onTouchMove = (e) => {setTouchEnd(e.targetTouches[0].clientX); setTouchEndY(e.targetTouches[0].clientY)}
    
    const onTouchEnd = () => {
      if (!touchStart || !touchEnd) return
      const distanceX = touchStart - touchEnd
      const distanceY = touchStartY - touchEndY
      const isLeftSwipe = distanceX > minSwipeDistance
      const isRightSwipe = distanceX < -minSwipeDistance

      if (isRightSwipe && Math.abs(distanceX) > distanceY){
        onSwipeRight();
      }
      else if (isLeftSwipe && distanceX > distanceY) 
      {
        onSwipeLeft();
      }

    }

  return (
    <div onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
        {children}
    </div>
  )
}
